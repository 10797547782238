<template>
  <div class="app-container-empty">
    <el-row>
      <el-col :style="{ width: 'calc(50% - 5px)' }">
        <el-card class="sitemapCard">
          <div slot="header" class="cardHeader">
            <el-row>
              <el-col :span="16">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  size="small"
                  @click="createRootNode()"
                >
                  {{ $t("sitemap.addRootSitemap") }}
                </el-button>
                <TenantSelect
                  v-if="showTenantSelector"
                  style="width: 200px; margin-left: 10px"
                  size="small"
                  @change="tenantChange"
                  v-model="currentTenantId"
                />
              </el-col>
              <el-col :span="8">
                <el-input
                  clearable
                  :placeholder="$t('commons.searchPhrase')"
                  maxlength="50"
                  v-model="filterText"
                >
                </el-input>
              </el-col>
            </el-row>
          </div>
          <div>
            <el-tree
              v-loading="loading"
              :data="data"
              node-key="id"
              @node-drag-end="handleDragEnd"
              :highlight-current="true"
              draggable
              :allow-drop="allowDrop"
              :filter-node-method="filterNode"
              :expand-on-click-node="false"
              ref="tree"
            >
              <span
                class="custom-tree-node"
                slot-scope="{ node, data }"
                @mouseenter="mouseoverNode(node)"
                @mouseleave="mouseoutNode(node)"
              >
                <span
                  @click="clickNode(node, data)"
                  :style="{
                    color: node.data.status == 0 ? '#AAAAAA' : '#606266',
                  }"
                >
                  {{ node.label }}
                </span>
                <span v-if="node.data.status == 0" class="tree-disable-span">
                  禁用
                </span>
                <template v-if="node.data.show">
                  <span>
                    <el-button
                      :style="{ marginLeft: '20px' }"
                      type="text"
                      @click="() => appendNode(node)"
                    >
                      <i class="el-icon-circle-plus-outline"></i>
                    </el-button>
                    <el-button
                      type="text"
                      @click="() => removeNode(node, data)"
                    >
                      <i class="el-icon-delete delete-btn"></i>
                    </el-button>
                  </span>
                </template>
              </span>
            </el-tree>
          </div>
        </el-card>
      </el-col>
      <el-col :style="{ width: 'calc(50% - 5px)', marginLeft: '10px' }">
        <el-card class="sitemapCard">
          <el-form
            label-position="top"
            :model="sitemaps.form"
            ref="sitemapForm"
            :rules="sitemaps.formRule"
          >
            <el-form-item prop="sitemapId" style="display: none">
              <el-input v-model="sitemaps.form.sitemapId"></el-input>
            </el-form-item>
            <el-form-item :label="$t('sitemap.name')" prop="displayText">
              <el-input
                maxlength="150"
                :show-word-limit="true"
                v-model="sitemaps.form.displayText"
                autocomplete="off"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('sitemap.urlType')" prop="urlType">
              <el-select v-model="sitemaps.form.urlType" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('sitemap.openWith')" prop="openWith">
              <el-select v-model="sitemaps.form.openWith" placeholder="请选择">
                <el-option
                  v-for="item in openWithOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('sitemap.href')" prop="href">
              <el-input
                maxlength="650"
                :show-word-limit="true"
                v-model="sitemaps.form.href"
                autocomplete="off"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('sitemap.permission')"
              prop="permissionCode"
            >
              <el-select
                style="width: 45%; margin-right: 5%"
                v-model="sitemaps.form.powerType"
                placeholder="请选择"
                @change="powerTypeChange"
              >
                <el-option
                  v-for="item in permissionSelectOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-cascader
                v-show="sitemaps.form.powerType === 'permission'"
                style="width: 50%"
                v-model="sitemaps.form.permissionCode"
                clearable
                :placeholder="$t('commons.selectPlease')"
                :options="permissionOptions"
              >
              </el-cascader>
              <el-select
                v-show="sitemaps.form.powerType === 'role'"
                style="width: 50%"
                v-model="sitemaps.form.roleId"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in roleOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="sitemaps.form.nodeLevel === 1"
              :label="$t('sitemap.icon')"
              prop="fileId"
              v-loading="uploadLoading"
            >
              <div class="form-image-line" style="display: flex">
                <el-upload
                  class="avatar-uploader"
                  :action="uploadPath"
                  :with-credentials="true"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <i class="el-icon-delete" @click="deleteEnterpriseLogo"></i>
              </div>
            </el-form-item>
            <el-form-item :label="$t('sitemap.icon')" prop="icon" v-else>
              <el-autocomplete
                clearable
                class="auto-customer"
                style="width: 100%"
                v-model="sitemaps.form.iconName"
                :fetch-suggestions="queryIcon"
                @select="handleIconSelect($event)"
                :placeholder="$t('commons.selectPlease')"
              >
                <template slot-scope="{ item }">
                  <div>
                    <svg-icon
                      style="width: 14px; height: 14px; margin-right: 6px"
                      :icon-class="item.itemCode"
                    />
                    {{ item.itemName }}
                    <span
                      style="float: right; color: #8492a6; font-size: 13px"
                      >{{ item.itemCode }}</span
                    >
                  </div>
                </template>
              </el-autocomplete>
              <span style="position: absolute; top: 2px; left: 8px">
                <svg-icon
                  style="width: 18px; height: 18px; margin-right: 6px"
                  :icon-class="sitemaps.form.icon"
                />
              </span>
            </el-form-item>
            <el-form-item :label="$t('sitemap.policy')" prop="policyId">
              <el-select
                v-model="sitemaps.form.policyId"
                clearable
                style="width: 100%"
              >
                <el-option
                  v-for="(item, key) in policyOptions"
                  :key="key"
                  :label="item"
                  :value="key"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="parentCode" style="display: none">
              <el-input v-model="sitemaps.form.parentCode"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('sitemap.parentLevel')"
              prop="parentText"
              style="display: none"
            >
              <el-input
                v-model="sitemaps.form.parentText"
                readonly="readonly"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('sitemap.isHomePage')" prop="policyId">
              <el-select v-model="sitemaps.form.homePage" style="width: 100%">
                <el-option
                  v-for="(item, key) in homePageOptions"
                  :key="key"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('commons.status')" prop="status">
              <el-select v-model="sitemaps.form.status" style="width: 100%">
                <el-option
                  v-for="(item, key) in statusOptions"
                  :key="key"
                  :label="item"
                  :value="key"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="nodeLevel" style="display: none">
              <el-input v-model="sitemaps.form.nodeLevel"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="saveSiteMap()">
                {{ $t("commons.save") }}
              </el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getSiteMapZtreeList,
  updateSiteMapNode,
  createSiteMap,
  updateSiteMap,
  deleteSiteMap,
  findIconCodeList,
} from "@/api/ruge/sitemap/sitemap";
import { getAllPermission } from "@/api/ruge/security/permission";
import { generateTitle } from "@/utils/i18n";
import { permissionStructTree } from "@/utils/tree";
import TenantSelect from "@/components/TenantSelect";
import { _sys_getRolePage } from "@/api/ruge/security/role";
import { envInfo } from "@/constants/envInfo";
export default {
  name: "SiteMap",
  components: {
    TenantSelect,
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    // 切换语言重新设置快捷选项
    language: function () {
      this.recursiveAttr(this.data);
    },
  },
  data() {
    return {
      options: [
        {
          value: "built",
          label: "内置",
        },
        {
          value: "embedded",
          label: "内嵌",
        },
        {
          value: "backlinks",
          label: "外链",
        },
      ],

      openWithOptions: [
        {
          value: "currentWindow",
          label: "当前窗口",
        },
        {
          value: "newWindow",
          label: "新窗口",
        },
      ],

      uploadLoading: false,
      imageUrl: "",
      uploadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/upload?ulType=DefaultUpload&scopeType=ALL",
      homePageOptions: [
        {
          label: "是",
          value: "true",
        },
        {
          label: "否",
          value: "false",
        },
      ],
      permissionSelectOptions: [
        {
          label: "权限点",
          value: "permission",
        },
        {
          label: "角色",
          value: "role",
        },
      ],
      roleOptions: [],
      currentTenantId: -1,
      loading: true,
      filterText: "",
      data: [],
      treeRootId: "rootNo",
      iconOptions: [],
      permissionOptions: [],
      defaultProps: {
        children: "children",
        label: "label",
        show: "show",
      },
      policyOptions: {
        1: this.$t("sitemap.sonShow"),
        2: this.$t("sitemap.everShow"),
        // 3: this.$t("sitemap.tenantShow"),
        // 4: this.$t("sitemap.platformShow"),
      },
      statusOptions: {
        1: this.$t("commons.active"),
        0: this.$t("commons.unActive"),
      },
      sitemaps: {
        show: false,
        label: null,
        children: null,
        nodes: null,
        id: null,
        form: {
          powerType: null,
          roleId: null,
          homePage: "false",
          sitemapId: null,
          displayText: null,
          href: null,
          urlType: null,
          openWith: null,
          permissionCode: null,
          icon: null,
          iconName: null,
          fileId: null,
          policyId: null,
          status: "1",
          parentText: this.$t("commons.empty"),
          parentCode: this.treeRootId,
          nodeLevel: 1,
          orderNo: 0,
          show: false,
          label: null,
          children: null,
        },
        formRule: {
          displayText: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          urlType: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          openWith: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          href: [
            {
              trigger: ["blur", "change"],
              validator: (rule, value, callback) => {
                if (
                  this.sitemaps.form.urlType === "built" &&
                  value &&
                  value.includes("http")
                ) {
                  callback(new Error("内置类型请勿配置全路径"));
                }
                callback();
              },
            },
          ],
          status: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
        },
      },
    };
  },
  created() {
    this.getSiteMapZtreeList(this.currentTenantId);
    this.getRoleList();
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
    tenantId() {
      this.currentTenantId = this.$store.getters.currentTenant;
    },
    showTenantSelector() {
      return this.$store.getters.tenantList.length > 1;
    },
  },
  methods: {
    handleAvatarSuccess(files, file, fileList) {
      this.sitemaps.form.fileId = files[0].fileId;
      this.imageUrl =
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
        files[0].fileId;
      this.uploadLoading = false;
    },
    beforeAvatarUpload(file, index) {
      console.log("file", file);
      const fileType = ["image/jpeg", "image/png", "image/svg+xml"];
      const isLt2M = file.size / 1024 / 1024 < 1;

      if (!fileType.includes(file.type)) {
        this.$message.error("上传图片只能是 JPG,PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }
      const returnFlag = fileType.includes(file.type) && isLt2M;
      this.uploadLoading = returnFlag;
      return returnFlag;
    },
    deleteEnterpriseLogo() {
      console.log("deleteEnterpriseLogo");
      this.imageUrl = "";
      this.sitemaps.form.fileId = null;
    },
    powerTypeChange() {
      this.sitemaps.form.roleId = null;
      this.sitemaps.form.permissionCode = null;
    },
    getRoleList(tenantId) {
      _sys_getRolePage({
        current: 1,
        rowCount: 10,
        tenantId: tenantId || this.currentTenantId,
      }).then((res) => {
        if (res && res.rows && res.rows.length) {
          this.roleOptions = res.rows.map((item) => {
            return {
              label: item.roleName,
              value: item.roleId,
            };
          });
        }
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    tenantChange(tenantId) {
      this.getSiteMapZtreeList(tenantId);
      this.getRoleList(tenantId);
    },
    getSiteMapZtreeList(tenantId) {
      this.loading = true;
      let params = { parentCode: this.treeRootId };
      if (tenantId) {
        params.tenantId = tenantId;
      }
      getSiteMapZtreeList(params)
        .then((response) => {
          this.recursiveAttr(response);
          this.createRootNode();
        })
        .finally(() => {
          this.loading = false;
        });

      //图标下拉框加载
      getAllPermission().then((resp) => {
        let cascadeList = null;
        cascadeList = permissionStructTree(resp, this);

        //将第三级的空子节点 children : []设置为 children : undefined
        cascadeList.forEach(function (obj) {
          obj.children.forEach(function (msg) {
            msg.children.forEach(function (msg) {
              msg.children = undefined;
            });
          });
        });
        this.permissionOptions = cascadeList;
      });
    },
    //递归修改属性及数据结构
    recursiveAttr(row) {
      let data = row.map((v) => {
        v.children = v.nodes;
        v.label = generateTitle(v.displayText);
        this.$set(v, "status", v.status + "");
        this.$set(v, "policyId", v.policyId + "");
        this.$set(v, "powerType", v.powerType + "");
        v.show = false;
        v.id = v.sitemapId;
        if (v.nodes != undefined) {
          this.recursiveAttr(v.nodes);
        }
        return v;
      });
      this.data = data;
    },
    //获取图标输入列表
    queryIcon(queryString, cb) {
      //图标下拉框加载
      findIconCodeList({ classifyCode: "sitemapIcon" }).then((response) => {
        this.iconOptions = response;
        let iconOptions = this.iconOptions;
        let results = queryString
          ? iconOptions.filter(this.createIconFilter(queryString))
          : iconOptions;
        // 调用 callback 返回建议列表的数据
        cb(results);
      });
    },
    //过滤图标列表
    createIconFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.itemCode
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) !== -1 ||
          restaurant.itemName
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) !== -1
        );
      };
    },
    //修改图标属性值
    handleIconSelect(data) {
      this.sitemaps.form.icon = data.itemCode;
      this.sitemaps.form.iconName = data.itemName;
    },
    allowDrop() {
      return true;
    },
    handleDragEnd(draggingNode, dropNode, dropType) {
      if (dropType == "before") {
        draggingNode.data.parentCode = dropNode.data.parentCode;
        draggingNode.data.nodeLevel = dropNode.data.nodeLevel;
        draggingNode.data.orderNo = dropNode.data.orderNo - 1;
      } else if (dropType == "after") {
        draggingNode.data.parentCode = dropNode.data.parentCode;
        draggingNode.data.nodeLevel = dropNode.data.nodeLevel;
        draggingNode.data.orderNo = dropNode.data.orderNo + 1;
      } else if (dropType == "inner") {
        draggingNode.data.parentCode = dropNode.data.parentCode;
        draggingNode.data.nodeLevel = dropNode.data.nodeLevel + 1;
        if (dropNode.data.nodes == undefined) {
          draggingNode.data.orderNo = 1;
        } else {
          draggingNode.data.orderNo = dropNode.data.nodes.length + 1;
        }
      } else if (dropType == "none") {
        return;
      }
      updateSiteMapNode(draggingNode.data)
        .then(() => {
          this.clearForm();
          // 成功提示
          this.$message({
            type: "success",
            message: this.$t("message.saveSuccess"),
          });
        })
        .catch((error) => {
          console.log(`保存失败，原因 => ${error}`);
        });
    },
    appendNode(node) {
      this.clearForm();
      this.sitemaps.form.parentCode = node.data.sitemapCode;
      this.sitemaps.form.parentText = generateTitle(node.data.displayText);
      this.sitemaps.form.nodeLevel = node.data.nodeLevel + 1;
      if (node.data.nodes == undefined) {
        this.sitemaps.form.orderNo = 1;
      } else {
        this.sitemaps.form.orderNo = node.data.nodes.length + 1;
      }
    },
    createRootNode() {
      this.clearForm();
      this.sitemaps.form.orderNo = this.data.length
        ? this.data[this.data.length - 1].orderNo + 1
        : 0;
    },
    saveSiteMap() {
      this.$refs.sitemapForm.validate((valid) => {
        if (Array.isArray(this.sitemaps.form.permissionCode)) {
          this.sitemaps.form.permissionCode =
            this.sitemaps.form.permissionCode[2];
        }
        if (valid) {
          //未点击添加主菜单直接保存时，取最大orderNo
          if (this.sitemaps.form.sitemapId == null) {
            this.sitemaps.form.orderNo = this.data.length
              ? this.data[this.data.length - 1].orderNo + 1
              : 0;
          }
          this.sitemaps.form.tenantId = this.currentTenantId || "";
          if (this.sitemaps.form.sitemapId != null) {
            updateSiteMap(this.sitemaps.form)
              .then(() => {
                this.updateTreeNode();
                // 成功提示
                this.$message({
                  type: "success",
                  message: this.$t("message.saveSuccess"),
                });
                const { homePage, href } = this.sitemaps.form;
                this.$store.commit(
                  "SET_TENANT_HOME_PAGE",
                  homePage === "true" ? href : "/personal"
                );
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          } else {
            createSiteMap(this.sitemaps.form)
              .then((node) => {
                this.createSonNode(node);
                if (node.parentCode == this.treeRootId) {
                  this.data.push(node);
                }
                // 成功提示
                this.$message({
                  type: "success",
                  message: this.$t("message.saveSuccess"),
                });
                const { homePage, href } = this.sitemaps.form;
                this.$store.commit(
                  "SET_TENANT_HOME_PAGE",
                  homePage === "true" ? href : "/personal"
                );
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          }
        }
      });
    },
    removeNode(node, data) {
      if (node.isLeaf) {
        this.$confirm(
          this.$t("message.deleteConfirm"),
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            type: "warning",
          }
        )
          .then(() => {
            deleteSiteMap({
              sitemapId: data.sitemapId,
              tenantId: this.currentTenantId || "",
            })
              .then(() => {
                // 成功提示
                this.$message({
                  type: "success",
                  message: this.$t("message.deleteSuccess"),
                });
                //异步更新
                const parent = node.parent;
                const children = parent.data.children || parent.data;
                const index = children.findIndex((d) => d.id === data.id);
                children.splice(index, 1);
              })
              .catch((error) => {
                console.log(`未删除，原因 => ${error}`);
              });
          })
          .catch((error) => {
            console.log(`未删除，原因 => ${error}`);
          });
      } else {
        // 失败提示
        this.$message({
          type: "info",
          message: "有子节点，不可删除",
        });
      }
    },
    mouseoverNode(node) {
      node.data.show = true;
    },
    mouseoutNode(node) {
      node.data.show = false;
    },
    clickNode(node, data) {
      this.sitemaps.form.sitemapId = data.sitemapId;
      this.sitemaps.form.displayText = data.displayText;
      this.sitemaps.form.href = data.href;
      this.sitemaps.form.powerType =
        data.powerType === "null" ? null : data.powerType;
      this.sitemaps.form.roleId = data.roleId;
      this.sitemaps.form.homePage = data.homePage;
      this.sitemaps.form.urlType = data.urlType;
      this.sitemaps.form.openWith = data.openWith;
      if (data.fileId) {
        this.imageUrl =
          envInfo.bgApp.archivePath +
          "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
          data.fileId;
      } else {
        this.imageUrl = "";
      }
      if (data.permissionCode) {
        this.sitemaps.form.permissionCode = [
          undefined,
          undefined,
          data.permissionCode,
        ];
      } else {
        this.sitemaps.form.permissionCode = null;
      }
      this.sitemaps.form.icon = data.icon;
      this.sitemaps.form.iconName = data.iconName;
      if (data.policyId != "null") {
        this.sitemaps.form.policyId = data.policyId;
      } else {
        this.sitemaps.form.policyId = "";
      }
      this.sitemaps.form.status = data.status;
      this.sitemaps.form.parentCode = data.parentCode;
      if (this.sitemaps.form.parentCode == this.treeRootId) {
        this.sitemaps.form.parentText = this.$t("commons.empty");
      } else {
        this.sitemaps.form.parentText = generateTitle(
          node.parent.data.displayText
        );
      }
      this.sitemaps.form.nodeLevel = data.nodeLevel;
      this.sitemaps.form.orderNo = data.orderNo;
    },
    clearForm() {
      this.sitemaps.form.sitemapId = null;
      this.sitemaps.form.displayText = null;
      this.sitemaps.form.href = null;
      this.sitemaps.form.permissionCode = null;
      this.sitemaps.form.icon = null;
      this.sitemaps.form.iconName = null;
      this.sitemaps.form.policyId = null;
      this.sitemaps.form.status = "1";
      this.sitemaps.form.parentText = this.$t("commons.empty");
      this.sitemaps.form.parentCode = this.treeRootId;
      this.sitemaps.form.nodeLevel = 1;
      this.sitemaps.form.orderNo = 0;
      this.sitemaps.form.powerType = null;
      this.sitemaps.form.roleId = null;
      this.sitemaps.form.homePage = "false";
      this.sitemaps.form.urlType = "built";
      this.sitemaps.form.openWith = "currentWindow";
    },
    //节点数据更新
    updateTreeNode() {
      this.$refs.tree.getCurrentNode().sitemapId = this.sitemaps.form.sitemapId;
      this.$refs.tree.getCurrentNode().displayText =
        this.sitemaps.form.displayText;
      this.$refs.tree.getCurrentNode().href = this.sitemaps.form.href;
      this.$refs.tree.getCurrentNode().urlType = this.sitemaps.form.urlType;
      this.$refs.tree.getCurrentNode().openWith = this.sitemaps.form.openWith;
      this.$refs.tree.getCurrentNode().permissionCode =
        this.sitemaps.form.permissionCode;
      this.$refs.tree.getCurrentNode().icon = this.sitemaps.form.icon;
      this.$refs.tree.getCurrentNode().iconName = this.sitemaps.form.iconName;
      this.$refs.tree.getCurrentNode().policyId = this.sitemaps.form.policyId;
      this.$refs.tree.getCurrentNode().status = this.sitemaps.form.status;
      this.$refs.tree.getCurrentNode().parentText =
        this.sitemaps.form.parentText;
      this.$refs.tree.getCurrentNode().parentCode =
        this.sitemaps.form.parentCode;
      this.$refs.tree.getCurrentNode().nodeLevel = this.sitemaps.form.nodeLevel;
      this.$refs.tree.getCurrentNode().orderNo = this.sitemaps.form.orderNo;
      this.$refs.tree.getCurrentNode().label = generateTitle(
        this.sitemaps.form.displayText
      );
    },
    createSonNode(node) {
      node.id = node.sitemapId;
      node.show = false;
      node.children = null;
      node.label = generateTitle(node.displayText);
      if (node.parentCode == this.treeRootId) {
        node.parentText = this.$t("commons.empty");
        node.children = [];
      } else {
        this.$refs.tree.store.nodesMap[
          this.$refs.tree.getCurrentNode().id
        ].expanded = true;
        node.parentText = generateTitle(
          this.$refs.tree.getCurrentNode().displayText
        );
        if (this.$refs.tree.getCurrentNode().children == null) {
          this.$refs.tree.getCurrentNode().children = [];
        }
        this.$refs.tree.getCurrentNode().children.push(node);
      }
    },
  },
};
</script>

<style scoped>
.sitemapCard {
  height: calc(100vh - 90px);
}

.sitemapCard >>> .el-card__header {
  padding: 20px;
}

.sitemapCard >>> .el-card__body {
  overflow-y: auto;
  height: calc(100%);
}

.sitemapCard >>> .el-tree {
  margin-bottom: 50px;
}

.cardHeader {
  line-height: 34px;
  font-weight: 600;
}
</style>
<style lang="less" scoped>
::v-deep .auto-customer {
  .el-input__inner {
    padding-left: 30px;
  }
}
.custom-tree-node {
  display: flex;
  align-items: center;
}
.tree-disable-span {
  display: inline-block;
  width: 28px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  border-radius: 2px;
  background: #e2e2e2;
  color: #949ba8;
  font-size: 10px;
  margin-left: 5px;
}
.avatar-uploader {
  border: 1px dotted #ccc;
  height: 80px;
  width: 80px;
}
.el-icon-delete {
  cursor: pointer;
  margin-left: 15px;
  color: #ff0000;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.upload-container {
  position: relative;
  display: flex;
  .tips-container {
    margin-left: 15px;
    font-size: 12px;
    & > div {
      height: 20px;
      line-height: 20px;
    }
    .delete-icon {
      position: absolute;
      bottom: 0;
      font-size: 14px;
      color: #ff0000;
      cursor: pointer;
    }
  }
}
</style>